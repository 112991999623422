import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DangerBox from 'components/Web_User_Interface/720p_Series/PTZ_Control/DangerBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "PTZ Control",
  "path": "/Web_User_Interface/720p_Series/PTZ_Control/",
  "dateChanged": "2017-12-08",
  "author": "Mike Polinowski",
  "excerpt": "Control your camera's pan, tilt and zoom function",
  "social": "/images/Search/QI_SearchThumb_WebUI_720p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='PTZ Control :: INSTAR HD Web User Interface' dateChanged='2017-12-10' author='Mike Polinowski' tag='INSTAR IP Camera' description='Every camera that can be panned, tilted or have a lense with a variable focal length can be controlled directly from the user interface.' image='/images/Search/QI_SearchThumb_WebUI_720p.png' twitter='/images/Search/QI_SearchThumb_WebUI_720p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/PTZ_Kontrolle/' locationFR='/fr/Web_User_Interface/720p_Series/PTZ_Control/' crumbLabel="PTZ Control" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "ptz-control",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ptz-control",
        "aria-label": "ptz control permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PTZ Control`}</h2>
    <h3 {...{
      "id": "control-pad",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#control-pad",
        "aria-label": "control pad permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Control Pad`}</h3>
    <p>{`You can use the control pad to pan and tilt your camera´s head. You can also start a horizontal (hscan) or vertical (vscan) patrol or stop the cameras movement (stop). The patrol mode will move the camera into the according terminal positions and then go back to the initial position. If you are using the Internet Explorer, you can also click into the boundary areas of the video frame to move the camera into the respective direction and double-clicking the centre of the frame will get you into a full screen mode. You can adjust the speed of your camera´s movement in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/System/PTZ/"
      }}>{`PTZ Settings`}</a>{`. Or program an automatic tour in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/System/PTZ_Tour/"
      }}>{`PTZ Tour Menu`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4072f2961c6f918a329cc2a93151a99e/5a46d/WebUI-PTZ_PTZ-Pad.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAACUElEQVQ4y31Uy3LaQBDUpzoHDs6FA5yc2PjAGQfKUEZgQcrECGIDcco8koN5yZinQLySr+lUL7XyIkQOUyvtzvb0zPSstlgs4bX5XK4Olss13t5GGI+n4pt7fnekaf4HjgvWav1GKBTC5/NzWNYrHOf/oNo7K8ddaba9wHq9hWEYuLiIIBK5RKlUxmq1EWfST73rAnrBHGelMPwFw8ghm71Ft9vH0lnjGAlfhgRhvciCwPxvt7siXX4z5enUxmw2962ppkagQ7VaQyqVQrn8HbOZLZyY5g5shX7/FYXCN1xfJ/Hy0nZB9xiqUcLhME5PP0LXM3h6+onN5o9ga9tzbLd/USyaiCcSODn5gKurL26TDgBl7RKJBKLRKHK5vEiVDHaAuyY9P9eRyWRxdvZJgJP90RoScDKZCWbtdkc4yzrym378Z6Pq9cZBc3y7zAtMTQJQ1Hd3BcGGLLlHpiyFn3Q0b9ulBmWKBGMD4vE4KpWqCMQOSy36MjwcvXdhm6aJQCCAYDAo0pQ18wK5gF52qnGPbDghtdoPV/B+fr5NkVpTjXusF83vbLFY7aesRul0ehgOx2JSpLEpTJ/TMZlMMRpNxD7VMByOxL6anaZq8P6+iHRax81NGqZZEsLVdV2AWNYA+fxXoUGunO9YLIZGo7k3LZpaA0bmaPER6PctwZhMGIwN4jlnutez0O32xMrzPYZqQWVdaARgQx4eKnh8rAixN5st4Stne1fDpX8NVZMvzWAwxO2tIbqcTKZEql5GXoVox15e6UimNO9beezF/gecHTgmaqR1OgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4072f2961c6f918a329cc2a93151a99e/e4706/WebUI-PTZ_PTZ-Pad.avif 230w", "/en/static/4072f2961c6f918a329cc2a93151a99e/e47c0/WebUI-PTZ_PTZ-Pad.avif 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4072f2961c6f918a329cc2a93151a99e/a0b58/WebUI-PTZ_PTZ-Pad.webp 230w", "/en/static/4072f2961c6f918a329cc2a93151a99e/c85cb/WebUI-PTZ_PTZ-Pad.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4072f2961c6f918a329cc2a93151a99e/81c8e/WebUI-PTZ_PTZ-Pad.png 230w", "/en/static/4072f2961c6f918a329cc2a93151a99e/5a46d/WebUI-PTZ_PTZ-Pad.png 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4072f2961c6f918a329cc2a93151a99e/5a46d/WebUI-PTZ_PTZ-Pad.png",
              "alt": "Web User Interface - 720p Series - PTZ Control",
              "title": "Web User Interface - 720p Series - PTZ Control",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "saved-positions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#saved-positions",
        "aria-label": "saved positions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Saved Positions`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c9cbe3544eb7d2ba02b15be85a5e48a4/5a46d/WebUI-PTZ_Positions.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAByElEQVQ4y81Uy1LCQBDM/3+Dj4MKFOAFFApQEEISyIvNZmMQQuJN/6KtWVgMIRRFlQcPzbAzk57u3U20IAjBGAfFS1H2nJZPFhvODVL1fJ+mEmyXWCyCPcIwkpGxbc9vjctc2VAtCLZkjBFBiE2yQZIkSNMUju0gSzPEcYw4XiLLPpGmmeyhfiI/VkjqGEckInieC8914Tg21qsVGGOwLBPdbgf6ZALTNPD9/QXf98A5h++z05YpRtE7RChkJCTJBmEoIES0rYkIy+UH1usEfLc9R4T5jS2CFBRzROL7QemBnCU8hWJ/fn1gmU5VRc6FhPqvakK8y6jqtC617Lo+JhMds5mN8ViHaVqYTk0YhgXLmmEweMV87qDXG8BxPLy8DGUvrWnYXiH90CRq6vcHGA7f0Ol0MRq9yYfa7eddfIKuG6hWa3LIaDTGdGqgUqnuVZ60rE5PWSTQ0KLlsrdGU8zUQBYsay7XnreQxLbtSmVkl06d6lRT9dJrQyB1tE9XV9fSxt3dPVqtNh4eKmg2H9FoNFGr1nBzc4t6vS7zvV7/wO7RtVF2aTqpUSD18l4W8nmFe8Jt4vLP1KmaVvwMXYpShX+J/0/4Ax0kV/iiZmbMAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c9cbe3544eb7d2ba02b15be85a5e48a4/e4706/WebUI-PTZ_Positions.avif 230w", "/en/static/c9cbe3544eb7d2ba02b15be85a5e48a4/e47c0/WebUI-PTZ_Positions.avif 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c9cbe3544eb7d2ba02b15be85a5e48a4/a0b58/WebUI-PTZ_Positions.webp 230w", "/en/static/c9cbe3544eb7d2ba02b15be85a5e48a4/c85cb/WebUI-PTZ_Positions.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c9cbe3544eb7d2ba02b15be85a5e48a4/81c8e/WebUI-PTZ_Positions.png 230w", "/en/static/c9cbe3544eb7d2ba02b15be85a5e48a4/5a46d/WebUI-PTZ_Positions.png 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c9cbe3544eb7d2ba02b15be85a5e48a4/5a46d/WebUI-PTZ_Positions.png",
              "alt": "Web User Interface - 720p Series - PTZ Control",
              "title": "Web User Interface - 720p Series - PTZ Control",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can save up to 8 camera orientations by moving the camera into the desired position and clicking on one of the numbered slots after choosing `}<strong parentName="p">{`Save Position`}</strong>{` from the drop-down menu. Clicking on the slots 1-8 when `}<strong parentName="p">{`Go to Position`}</strong>{` is selected will move the camera back to the saved position. You can delete all positions by clicking on Reset all Positions. Position 1 defines the start-up position of your camera!`}</p>
    <DangerBox mdxType="DangerBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      